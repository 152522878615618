<template>
  <div class="visitor-status" :style="status.style">
    <VisitorStatusIcon :score="score" :online="online" :isVegaWidgetEnabled="isVegaWidgetEnabled" />
    <div class="visitor-status--text" :class="`visitor-status--${status.value}`" :style="colorText ? `color: ${colorText} !important` : ''">
      {{ status.text }}
    </div>
  </div>
</template>

<script>
import VisitorStatusIcon from '@/components/vega/VisitorStatusIcon'

export default {
  name: 'VisitorStatus',
  props: {
    score: {
      type: Number,
      default: 0
    },
    online: {
      type: Boolean,
      default: false
    },
    isVegaWidgetEnabled: {
      type: Boolean,
      default: false
    },
    colorText: {
      type: String,
      default: ''
    }
  },
  components: {
    VisitorStatusIcon
  },
  computed: {
    scoring() {
      const score = this.score > 1 ? this.score / 10 : this.score
      return score ? score * 100 : null
    },
    status() {
      if (!this.isVegaWidgetEnabled || this.isVegaPaused) {
        return {
          value: 'locked',
          text: 'Not enabled',
          style: {
            background: 'rgba(255, 255, 255,1)',
            color: '#C4C4C4'
          }
        }
      }

      if (!this.isVegaActivated || this.vegaWillExceedDailyLimit) {
        return {
          value: 'locked',
          text: 'Locked',
          style: {
            background: 'rgba(255, 255, 255,1)',
            color: '#C4C4C4'
          }
        }
      }

      if (!this.scoring) {
        return {
          value: 'neutral',
          text: 'Neutral',
          style: {
            background: 'rgba(255, 255, 255,1)',
            color: '#C4C4C4'
          }
        }
      }

      if (this.scoring >= 83) {
        return {
          value: 'onfire',
          text: 'On Fire',
          style: {
            background: 'rgba(254, 18, 78, 0.2)'
          }
        }
      } else if (this.scoring >= 67 && this.scoring < 83) {
        return {
          value: 'hot',
          text: 'Hot',
          style: {
            background: 'rgba(224, 0, 0, 0.2)'
          }
        }
      } else if (this.scoring >= 50 && this.scoring < 67) {
        return {
          value: 'warm',
          text: 'Warm',
          style: {
            background: 'rgba(241, 163, 66, 0.2)'
          }
        }
      } else if (this.scoring >= 33 && this.scoring < 50) {
        return {
          value: 'mild',
          text: 'Mild',
          style: {
            background: 'rgba(250, 217, 2, 0.2)'
          }
        }
      } else if (this.scoring >= 17 && this.scoring < 33) {
        return {
          value: 'cold',
          text: 'Cold',
          style: {
            background: 'rgba(151, 248, 121, 0.2)'
          }
        }
      } else {
        return {
          value: 'frozen',
          text: 'Frozen',
          style: {
            background: 'rgba(30, 248, 240, 0.2)'
          }
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.visitor-status {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  padding: 5px 15px;
  border-radius: 6px;

  &--text {
    margin-left: 10px;
    line-height: 24px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    letter-spacing: -0.177778px;
    width: max-content;
  }

  &--icon {
    &--warm {
      height: 16px;
    }
    &--neutral {
      height: 16px;
    }
    &--frozen {
      height: 16px;
    }
    &--mild {
      height: 15px;
    }
    &--hot {
      height: 16px;
    }
    &--onfire {
      height: 16px;
    }
    &--cold {
      height: 16px;
    }
  }

  &--warm {
    color: #f1a342;
  }
  &--neutral {
    color: #c4c4c4;
  }
  &--frozen {
    color: #1ef8f0;
  }
  &--onfire {
    color: #fe124e;
  }
  &--hot {
    color: #e00000;
  }
  &--mild {
    color: #fad902;
  }
  &--cold {
    color: #97f879;
  }
  &--locked {
    color: #c4c4c4;
  }
  &--not-enabled {
    color: #c4c4c4;
  }
}
</style>
